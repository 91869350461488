import type { TemplateName } from 'lib/page-props';

import Layout from './Layout';
import LayoutAccount from './LayoutAccount';
import LayoutCart from './LayoutCart';
import LayoutCheckout from './LayoutCheckout';
import LayoutOrderStatus from './LayoutOrderStatus';
import LayoutSharedList from './LayoutSharedList';
import LayoutWishlist from './LayoutWishlist';

export default function getLayout(templateName: TemplateName) {
	switch (templateName) {
		case 'Account Root Route':
		case 'Account Route':
			return LayoutAccount;
		case 'Cart Route':
			return LayoutCart;
		case 'Checkout Route':
		case 'Identification Route':
			return LayoutCheckout;
		case 'My Order Route':
			return LayoutOrderStatus;
		case 'Wishlist Route':
			return LayoutWishlist;
		case 'Shared Wishlist Route':
			return LayoutSharedList;
		default:
			return Layout;
	}
}
