import React, { useEffect } from 'react';
import { handleEditorFastRefresh } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { parse as parseCookie } from 'cookie';
import { GetServerSideProps } from 'next';

import ErrorBoundary from 'components/ErrorBoundary';
import GlobalPopovers from 'components/GlobalPopovers';
import PageOverlay from 'components/PageOverlay';
import { PortalContainers } from 'components/Portal';
import Toast from 'components/Toast';
import { publicRuntimeConfig } from 'config';
import GlobalContexts from 'contexts';
import getLayout from 'layouts/getLayout';
import { SitecorePageProps, type UserData } from 'lib/page-props';
import { sitecorePagePropsFactory } from 'lib/page-props-factory';
import type { DecodedUserJWTToken } from 'types';
import { is } from 'utils/helpers';
import jwtDecode from 'utils/jwtDecode';

function readCookies(cookie: string | undefined): UserData | null {
	if (!cookie) {
		return null;
	}
	const props: UserData = {};
	const cookies = parseCookie(cookie);
	const authCookieName =
		publicRuntimeConfig?.NEXT_PUBLIC_AUTH_TOKEN_COOKIE_NAME;
	const jwtToken = authCookieName ? cookies[authCookieName] : '';

	try {
		props.selectedStore = cookies.selectedStore
			? JSON.parse(cookies.selectedStore)
			: null;
	} finally {
		// Do nothing
	}
	props.usePriceExcVat = Boolean(cookies.usePriceExcVat) || false;

	if (jwtToken) {
		const jwtData = jwtDecode<DecodedUserJWTToken>(jwtToken);
		if (jwtData) {
			props.loginMethod = jwtData.idp;
			props.userType = jwtData.customer_type_specific;
			props.customerType = jwtData.customer_type || 'Anonymous';
		}
	}
	return props;
}

// This function gets called at request time on server-side.
export const getServerSideProps: GetServerSideProps = async (context) => {
	const props: SitecorePageProps = {
		...(await sitecorePagePropsFactory.create(context)),
		userData: readCookies(context?.req?.headers?.cookie),
	};

	const redirect = props.layoutData.sitecore.context?.redirect;
	if (
		is.object(redirect) &&
		'location' in redirect &&
		'statusCode' in redirect
	) {
		return {
			props,
			redirect: {
				destination: redirect.location,
				statusCode: redirect.statusCode,
			},
		};
	}
	return { props };
};

export default function SitecorePage({
	componentProps,
	dictionary,
	layoutData,
	userData,
}: SitecorePageProps): JSX.Element {
	useEffect(() => {
		// Since Experience Editor does not support Fast Refresh need to refresh EE chromes after Fast Refresh finished
		handleEditorFastRefresh();
	}, []);

	const { route } = layoutData.sitecore;

	const LayoutComponent = getLayout(
		route?.templateName || 'Generic Page Route',
	);

	return (
		<ErrorBoundary>
			<GlobalContexts
				componentProps={componentProps}
				layoutData={layoutData}
				dictionary={dictionary}
				userData={userData ?? undefined}
			>
				<LayoutComponent layoutData={layoutData} />
				<GlobalPopovers />

				<Toast />

				<>
					{/* Everything in this fragment should probably be kept
						    in sync with StorybookWrapper. */}
					<PortalContainers />
					<PageOverlay />
				</>
			</GlobalContexts>
		</ErrorBoundary>
	);
}
SitecorePage.displayName = 'SitecorePage';
