import React from 'react';

import GlobalCartAddedProductPopover from 'components/GlobalCartAddedProductPopover';
import { GlobalStockStoreSelectorPopover } from 'components/GlobalStoreSelector';
import { PartialPopover } from 'components/Popover';
import UserInformationValidationPopover from 'components/UserInformationValidationPopover';
import {
	useGlobalMemberPopoversContext,
	useGlobalPopoversContext,
} from 'contexts';
import { useGlobalLinks } from 'hooks';
import { useI18n } from 'utils/i18n';

// TODO the partial forms here should not need to be global,
// but as they are called for from the authMachine they need to exist somewhere
export default function GlobalPopovers() {
	const { t } = useI18n();
	const { contactForm, customerChat, signUpJulaClub, signUpJulaPro } =
		useGlobalLinks();

	const {
		closeContactForm,
		closeCustomerChat,
		isContactFormOpen,
		isCustomerChatOpen,
	} = useGlobalPopoversContext();

	const {
		closeSignUpJulaClub,
		closeSignUpJulaPro,
		isSignUpJulaClubOpen,
		isSignUpJulaProOpen,
	} = useGlobalMemberPopoversContext();

	return (
		<>
			<GlobalCartAddedProductPopover />
			<GlobalStockStoreSelectorPopover />
			<UserInformationValidationPopover />
			<PartialPopover
				heading={t('account_julapro_become_member_button')}
				isOpen={isSignUpJulaProOpen}
				item={signUpJulaPro}
				onClose={closeSignUpJulaPro}
			/>
			<PartialPopover
				heading={t('account_julaclub_become_member_button')}
				isOpen={isSignUpJulaClubOpen}
				item={signUpJulaClub}
				onClose={closeSignUpJulaClub}
			/>
			<PartialPopover
				heading={t('contact_form_popover_title')}
				isOpen={isContactFormOpen}
				item={contactForm}
				onClose={closeContactForm}
			/>
			<PartialPopover
				heading={t('customer_service_chat_popover_heading')}
				isOpen={isCustomerChatOpen}
				item={customerChat}
				onClose={closeCustomerChat}
			/>
		</>
	);
}
GlobalPopovers.displayName = 'GlobalPopovers';
