import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { useSharedWishlist } from 'hooks';
import BaseLayout from 'layouts/BaseLayout';
import type { CustomLayoutServiceData, UserData } from 'lib/page-props';
import { ignorePromiseRejection } from 'utils/helpers';

interface Props {
	layoutData: CustomLayoutServiceData;
	userInfo?: UserData;
}

export default function LayoutSharedList({ layoutData, userInfo }: Props) {
	const { route } = layoutData.sitecore;

	const router = useRouter();

	// use this hack to get around a weird bug where
	// swr on  the same level/adjacent to a suspense boundary will cause
	// weird suspense boundary issue if swr has started fetching data on the server
	// in this case it clashes with the suspense boundary Sitecore has inside the Placeholder component
	const [hydrated, setHydrated] = useState(false);
	useEffect(() => {
		setHydrated(true);
	}, []);

	const { error, isLoading } = useSharedWishlist(hydrated);

	useEffect(() => {
		if (error) {
			ignorePromiseRejection(router.push('/404'));
		}
	}, [error, router]);

	return (
		<BaseLayout
			forceNoindexNofollow
			layoutData={layoutData}
			route={route}
			renderMain={!isLoading && !error}
			renderLoading={isLoading || error}
		/>
	);
}
LayoutSharedList.displayName = 'LayoutSharedList';
