import React, { useEffect } from 'react';
import { useSelector } from '@xstate/react';

import { useGlobalMachinesContext, useSelectedStore } from 'contexts';
import { useIsEditing } from 'hooks';
import type { CustomLayoutServiceData } from 'lib/page-props';
import {
	selectIsInitialLoading,
	selectWishlistHasVariants,
} from 'state-machines/wishlist';

import BaseLayout from './BaseLayout';

interface Props {
	layoutData: CustomLayoutServiceData;
}

export default function LayoutWishlist({ layoutData }: Props) {
	const { route } = layoutData.sitecore;
	const { wishlistService } = useGlobalMachinesContext();
	const isEditing = useIsEditing();

	const { selectedStore } = useSelectedStore();
	useEffect(() => {
		wishlistService.send({
			type: 'FETCH_WISHLIST',
			storeId: selectedStore?.id,
		});
	}, [selectedStore, wishlistService]);
	const hasVariants = useSelector(wishlistService, selectWishlistHasVariants);

	const initialLoading = useSelector(wishlistService, selectIsInitialLoading);
	return (
		<BaseLayout
			forceNoindexNofollow
			layoutData={layoutData}
			route={route}
			renderMain={isEditing || (!initialLoading && hasVariants)}
			renderEmpty={isEditing || (!initialLoading && !hasVariants)}
			renderLoading={!isEditing && initialLoading}
		/>
	);
}
LayoutWishlist.displayName = 'LayoutWishlist';
