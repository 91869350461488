import React from 'react';

import type { CustomLayoutServiceData } from 'lib/page-props';

import BaseLayout from './BaseLayout';

interface Props {
	layoutData: CustomLayoutServiceData;
}

export default function Layout({ layoutData }: Props) {
	const { route } = layoutData.sitecore;

	return <BaseLayout layoutData={layoutData} route={route} />;
}
Layout.displayName = 'Layout';
